import type { Folder } from '@respell/database';
import type {
  OrganizationUserFilesToSyncFilters,
  UserFile,
} from 'carbon-typescript-sdk';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { useCarbon } from '~/composables/useCarbon';
import { useWorkspaceStore } from '~/stores/workspaces';

// I do not believe the Carbon SDK surfaces a type for the metadata
type FileMetadata = {
  // id of the folder the file is associated with
  parentId: string | null;

  // Type of parent, examples: 'NOTION_WORKSPACE',
  parentType: string | null;

  // Whether remote file is a folder
  isFolder: boolean | null;

  // Unknown at this time
  parentExternalFileId: number | null;

  // External file id, also stored as external_file_id on parent, EX: '1KEBgk8GhTkQpEqxJZTzD-i8-DbBgD1JNr8dt7AdkP78'
  rootExternalFileId: string | null;
};

type DataSource = {
  createdAt: string;
  fileFormat: string | null;
  fileSize: string | null;
  id: number;
  parentId: number;
  lastSync: string | null;
  name: string | null;
  source: string | null;
  updatedAt: string;
  fileMetadata: FileMetadata | null;
};

export const useDataSourcesStore = defineStore('dataSources', {
  state: () => ({
    count: 0,
    parent: null as DataSource | null,
    grandParent: null as DataSource | null,
    currentFolder: null as Folder,
    folders: null as Folder[] | null,
  }),
  // persist: true,
  actions: {
    async fetchDataSources(
      parentFileOrFolderId?: number | string | null,
      searchQuery?: string | null,
    ) {
      const isFolderId =
        parentFileOrFolderId !== '' &&
        isNaN(parseInt(parentFileOrFolderId as string, 10));
      const parentId = isFolderId
        ? parentFileOrFolderId
        : parseInt(parentFileOrFolderId as string, 10) || null;

      // We must have the users folders to include or exclude records appropriately
      if (this.folders === null) {
        await this.fetchFolders();
      }

      if (isFolderId) {
        if (searchQuery) {
          return await this.searchDataSourcesByFolderId(
            searchQuery,
            parentFileOrFolderId,
          );
        } else {
          return await this.fetchDataSourcesByFolderId(parentFileOrFolderId);
        }
      } else {
        if (searchQuery) {
          return await this.searchDataSourcesByParentFileId(
            searchQuery,
            parentId,
          );
        } else {
          return await this.fetchDataSourcesByParentId(parentId);
        }
      }
    },
    async fetchDataSourcesByParentId(parentFileId?: number | null) {
      this.currentFolder = null;

      let filters: OrganizationUserFilesToSyncFilters;

      // We are querying by a parent folder
      if (parentFileId) {
        filters = {
          parent_file_ids: [parentFileId],
          // parent_file_ids: [router.currentRoute.value.params.dataSourceId],
        };
        this.loadParent(parentFileId);
      } else {
        // We don't have a folder, so query root files
        filters = {
          root_files_only: true,
        };
        this.parent = null;
        this.grandParent = null;
      }

      return await this.queryCarbonWithFilters(filters);
    },
    async fetchDataSourcesByFolderId(folderId: string) {
      let filters: OrganizationUserFilesToSyncFilters;

      this.currentFolder = this.folders.filter((f) => f.id === folderId)[0];

      // This allows us to only view the folders contents when on a folder page
      filters = {
        tags_v2: {
          key: 'folderId',
          value: folderId,
        },
        ...filters,
      };

      return await this.queryCarbonWithFilters(filters);
    },
    async loadParent(fileId?: number) {
      const { carbon } = useCarbon();

      this.grandParent = null;
      let dataSources: DataSource[];
      let parent: DataSource;

      if (carbon && fileId) {
        const response = await carbon.files.queryUserFiles({
          filters: {
            ids: [fileId],
          },
        });

        dataSources = await this.mapResultsToDataSources(response.data.results);

        parent = dataSources[0];

        if (parent.parentId && !this.grandParent) {
          this.grandParent = await this.loadParent(parent.parentId);
        }

        this.parent = parent;

        return this.parent;
      } else {
        return null;
      }
    },
    async mapResultsToDataSources(results: Array<UserFile>) {
      const dataSources: DataSource[] = results.map(
        ({
          id,
          parent_id,
          name,
          last_sync,
          file_statistics,
          created_at,
          source,
          updated_at,
          file_metadata,
        }) => ({
          createdAt: created_at,
          fileFormat: file_statistics?.file_format,
          fileSize: file_statistics?.file_size,
          id,
          parentId: parent_id,
          lastSync: last_sync,
          name,
          source,
          updatedAt: updated_at,
          fileMetadata: {
            parentId: file_metadata?.parent_id,
            parentType: file_metadata?.parent_type,
            isFolder: file_metadata?.is_folder,
            parentExternalFileId: file_metadata?.parent_external_file_id,
            rootExternalFileId: file_metadata?.root_external_file_id,
          },
        }),
      );
      return dataSources;
    },
    async loadDataSourcesByFileIds(fileIds: number[]) {
      const filters: OrganizationUserFilesToSyncFilters = {
        ids: fileIds,
      };

      return await this.queryCarbonWithFilters(filters);
    },
    async queryCarbonWithFilters(filters: OrganizationUserFilesToSyncFilters) {
      const { carbon } = useCarbon();

      // Handle folders
      const folderIds = this.currentFolder
        ? [
            {
              key: 'folderId',
              value: this.currentFolder.id,
            },
          ]
        : this.folders?.map((folder) => ({
            key: 'folderId',
            value: folder.id,
            negate: true,
          })) || [];

      if (carbon) {
        const workspaceTags = {
          AND: [
            {
              key: 'ownerType',
              value: 'Workspace',
            },
            ...folderIds,
          ],
        };

        const allFilters = {
          pagination: {
            limit: 250, // Limit cannot exceed 250 per folder
          },
          order_by: 'created_at',
          order_dir: 'desc',
          include_raw_file: true,
          include_parsed_text_file: true,
          filters: {
            tags_v2: workspaceTags, // In the case of folders, these are overwritten intentionally
            ...filters,
          },
        };

        const userFiles = await carbon.files.queryUserFiles(allFilters);

        this.count = userFiles.data.count;

        return await this.mapResultsToDataSources(userFiles.data.results);
      }
    },
    async searchDataSourcesByParentFileId(
      query: string,
      parentFileId: number | null | undefined,
    ) {
      const { carbon } = useCarbon();

      const embeddings = await carbon?.embeddings.getDocuments({
        query: query,
        k: 1,
        // media_type: "TEXT",
        // embedding_model: "OPENAI",
        parent_file_ids: parentFileId ? [parentFileId] : null,
        tags_v2: {
          key: 'ownerType',
          value: 'Workspace',
        },
      });

      // Nice to have: We could update the search results to sort using this data
      const queriedFiles = embeddings?.data.documents.map((document: any) => ({
        file_id: document.file_id,
        rank: document.rank,
        score: document.score,
      }));

      const fileIds: number[] | undefined = queriedFiles?.map((file: any) =>
        Number(file.file_id),
      );

      if (fileIds) {
        return this.loadDataSourcesByFileIds(fileIds);
      } else {
        return [];
      }
    },
    async searchDataSourcesByFolderId(
      query: string,
      folderId: string | null | undefined,
    ) {
      const { carbon } = useCarbon();

      const embeddings = await carbon?.embeddings.getDocuments({
        query: query,
        k: 1,
        // media_type: "TEXT",
        // embedding_model: "OPENAI",
        // parent_file_ids: parentFileId ? [parentFileId] : null,
        tags_v2: {
          AND: [
            {
              key: 'ownerType',
              value: 'Workspace',
            },
            {
              key: 'folderId',
              value: folderId,
            },
          ],
        },
      });

      // Nice to have: We could update the search results to sort using this data
      const queriedFiles = embeddings?.data.documents.map((document: any) => ({
        file_id: document.file_id,
        rank: document.rank,
        score: document.score,
      }));

      const fileIds: number[] | undefined = queriedFiles?.map((file: any) =>
        Number(file.file_id),
      );

      if (fileIds) {
        return this.loadDataSourcesByFileIds(fileIds);
      } else {
        return [];
      }
    },
    async deleteById(fileId: number) {
      const { carbon } = useCarbon();

      if (carbon) {
        await carbon.files.deleteV2({
          filters: {
            ids: [fileId],
          },
        });
      }
    },
    async getUrlByFileId(fileId: number) {
      const { carbon } = useCarbon();

      if (carbon) {
        const getRawFileResponse = await carbon.files.queryUserFiles({
          filters: {
            ids: [fileId],
          },
          include_raw_file: true,
        });

        return getRawFileResponse.data.results[0].presigned_url;
      } else {
        return [];
      }
    },
    async createFileFolder(event: FormSubmitEvent) {
      const workspaceStore = useWorkspaceStore();
      const { workspaceTeam } = storeToRefs(workspaceStore);

      const { data: inputForm } = event;
      const folderInput = {
        ...inputForm,
        type: 'file',
        teamId: workspaceTeam.value.id,
      };

      try {
        const folder = await $api<Folder>(`/api/folders`, {
          method: 'POST',
          body: folderInput,
        });

        this.folders.push(folder);
      } catch (error) {
        console.error('Failed to create folder:', error);
        return null;
      }
      return this.folder;
    },
    async fetchFolders() {
      const workspaceStore = useWorkspaceStore();
      const { workspaceTeam } = storeToRefs(workspaceStore);

      const { data: folders } = await useApi<{}>('/api/folders', {
        method: 'GET',
        query: {
          teamId: workspaceTeam.value.id,
          type: 'file',
        },
      });

      this.folders = folders.value;

      return this.folders;
    },
    async deleteFolder(folderId) {
      await $api<Folder>(`/api/folders/${folderId}`, {
        method: 'DELETE',
      });

      await this.fetchFolders();
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataSourcesStore, import.meta.hot));
}
