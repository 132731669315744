<script setup lang="ts">
import { useCarbon } from '~/composables/useCarbon';
import { sourceToIcon } from '~/util/constants';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const workspaceStore = useWorkspaceStore();
const { workspace } = storeToRefs(workspaceStore);

// TODO: ENG-700/integrate-nango-and-carbon
// import { nanoid } from 'nanoid';
// import Nango from '@nangohq/frontend';
// let nango = new Nango({ publicKey: process.env.NANGO_PUBLIC_KEY });

// const CARBON_INTEGRATIONS = ['GOOGLE_DRIVE', 'NOTION'];

const serviceToAttributes = {
  GOOGLE_DRIVE: {
    integrationName: 'Google Drive',
  },
  NOTION: {
    integrationName: 'Notion',
  },
};

const { service } = defineProps({
  service: {
    type: String,
    required: true,
  },
});

const { carbon } = useCarbon();
const oauthUrl = ref(null);

const fetchOAuthUrl = async () => {
  try {
    const response = await carbon.integrations.getOauthUrl({
      service: service,
      chunk_size: 1500,
      chunk_overlap: 20,
      skip_embedding_generation: false,
      embedding_model: 'OPENAI',
      generate_sparse_vectors: false,
      prepend_filename_to_chunks: false,
      sync_files_on_connection: true,
      set_page_as_boundary: false,
      connecting_new_account: false,
      use_ocr: false,
      parse_pdf_tables_with_ocr: false,
      tags: {
        ownerType: 'Workspace',
        ownerId: workspace.value.id,
        creatorId: user.value.id,
        workspaceId: workspace.value.id,
      },
    });

    oauthUrl.value = response.data.oauth_url;
  } catch (error) {
    console.error('Error fetching OAuth URL:', error);
  }
};

onMounted(fetchOAuthUrl);

const openOauthUrl = () => {
  navigateTo(oauthUrl.value, {
    external: true,
    open: {
      target: '_blank',
    },
  });
};

const { integrationName } = serviceToAttributes[service];
</script>
<template>
  <UButton
    :icon="sourceToIcon[service]"
    :loading="oauthUrl === null"
    :disabled="oauthUrl === null"
    size="xl"
    block
    color="white"
    @click="openOauthUrl"
  >
    {{ integrationName }}
  </UButton>
</template>
