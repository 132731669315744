<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import { FolderType } from '@respell/database';
import { z } from 'zod';

const modal = useModal();
const toast = useToast();

const schema = z.object({
  name: z.string().min(1),
});

const { type } = defineProps({
  type: {
    type: FolderType,
    required: true,
  },
});

type Schema = z.output<typeof schema>;

const dataSourcesStore = useDataSourcesStore();
const { createFileFolder } = dataSourcesStore;

const state = reactive({
  name: undefined,
});

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  await createFileFolder(event);

  modal.close();

  toast.add({
    title: 'Successfully created folder.',
    id: 'modal-success',
  });
};
</script>
<template>
  <UModal>
    <UForm
      :schema="schema"
      :state="state"
      class="w-full space-y-4"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Create a new folder</div>
        </template>

        <div class="space-y-4">
          <UFormGroup label="Name" name="name" required>
            <UInput v-model="state.name" placeholder="My folder..." required />
          </UFormGroup>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-2">
            <UButton type="button" size="xl" color="white" @click="modal.close"
              >Cancel
            </UButton>
            <UButton type="submit" size="xl">Create Folder</UButton>
          </div>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
