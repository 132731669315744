export const sourceToIcon = {
  GOOGLE_DRIVE: 'i-logos-google-drive',
  NOTION: 'i-logos-notion-icon',
};

enum FileFormatIcons {
  code = 'i-ph-file-code',
  file = 'i-ph-file',
  folder = 'i-ph-folder-simple',
  audio = 'i-ph-file-audio',
  pdf = 'i-ph-file-pdf',
  spreadsheet = 'i-ph-grid-nine',
  googleDocument = 'i-mdi-file-document',
  googleSpreadsheet = 'i-mdi-google-spreadsheet',
  googlePresentation = 'i-mdi-file-presentation-box',
  text = 'i-ph-file-txt',
  doc = 'i-ph-file-doc',
  mp3 = 'i-ph-file-audio',
  png = 'i-ph-file-png',
  jpg = 'i-ph-file-jpg',
  presentation = 'i-ph-browser',
}

export const fileFormatToIcon = {
  CSV: FileFormatIcons.spreadsheet,
  DOCX: FileFormatIcons.text,
  GOOGLE_DOCS: FileFormatIcons.googleDocument,
  GOOGLE_SHEETS: FileFormatIcons.googleSpreadsheet,
  GOOGLE_SLIDES: FileFormatIcons.googlePresentation,
  MD: FileFormatIcons.text,
  PNG: FileFormatIcons.png,
  MP3: FileFormatIcons.mp3,
  PDF: FileFormatIcons.pdf,
  JPG: FileFormatIcons.jpg,
  JPEG: FileFormatIcons.jpg,
  NOTION: FileFormatIcons.text,
  RTF: FileFormatIcons.text,
  TSV: FileFormatIcons.spreadsheet,
  TXT: FileFormatIcons.text,
  XLSX: FileFormatIcons.spreadsheet,
};
